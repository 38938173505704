<template>
  <section class="section-border border-primary">
    <div class="container d-flex flex-column">
      <div
        class="row align-items-center justify-content-center gx-0 min-vh-100"
      >
        <div
          class="
            col-8 col-md-6 col-lg-7
            offset-md-1
            order-md-2
            mt-auto mt-md-0
            pt-8
            pb-4
            py-md-11
          "
        >
          <!-- Image -->
          <img
            src="assets/img/notfound.png"
            alt="Not found "
            class="img-fluid"
          />
        </div>
        <div
          class="
            col-12 col-md-5 col-lg-4
            order-md-1
            mb-auto mb-md-0
            pb-8
            py-md-11
          "
        >
          <!-- Heading -->
          <h1 class="display-3 fw-bold text-center">Oops!</h1>

          <!-- Text -->
          <p class="mb-5 text-center text-muted">
            You seem lost, but don’t worry, we’ll take care of it for sure.
          </p>

          <!-- Link -->
          <div class="text-center">
              <router-link to="/" class="btn btn-primary">Back to home</router-link>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
<style scoped>
</style>